<template>
  <div>
    <div v-if="! guest.id" class="mt-12 max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div v-else class="flex flex-col xl:flex-row mt-4 xl:mt-12 w-full">
      <div class="xl:mt-12 mx-auto">
        <profile-navigation @changeSection="changeSection"/>
      </div>
      <div class="flex flex-col w-full mt-12 bg-white shadow-xl rounded-br-xl rounded-bl-xl">
        <div class="flex flex-col relative w-full h-14 bg-blue-800 rounded-tr-xl rounded-tl-xl">
          <div class="flex flex-row h-full justify-start">
            <h1 class="text-white text-lg font-semibold ml-12 my-auto">{{sectionName}}</h1>
            <ToggleSwitch v-if="section == 2" :active="pitchSettings.is_enabled" @toggled="togglePitch"/>
          </div>
        </div>

        <div class="details-and-presskit space-y-8 sm:space-y-0" v-if="section == 1">
          <div class="details-item">
            <guest-name/>
          </div>
          <!-- <div class="details-item">
            <guest-pronunciation/>
          </div> -->
          <div class="details-item">
            <guest-title/>
          </div>
          <div class="details-item">
            <guest-image/>
          </div>
          <div class="details-item">
            <profile-categories :modelId="guest.id" entity="guest"/>
          </div>
          <div class="details-item">
            <guest-tags/>
          </div>
          <div class="details-item">
            <press-kit-bio :model="guest" entity="guest" />
          </div>
          <!-- <div class="details-item">
            <media-kit-link :model="guest" entity="guest" />
          </div> -->
          <div class="details-item">
            <guest-links/>
          </div>
          <div class="details-item">
            <guest-videos/>
          </div>
          <div class="details-item">
            <guest-articles/>
          </div>
          <div class="details-item">
            <guest-podcasts/>
          </div>
          <div class="details-item">
            <press-kit-photos :model="guest" entity="guest" />
          </div>
          <div class="details-item">
            <press-kit-logos :model="guest" entity="guest" />
          </div>
          <div class="details-item">
            <guest-experience/>
          </div>
          <div class="details-item">
            <guest-topics/>
          </div>
          <div class="details-item">
            <guest-questions/>
          </div>
          <!-- <div class="details-item">
            <guest-visibility/>
          </div> -->
        </div>

        <!-- <div class="mt-20">
          <div class="mt-12 space-y-8 sm:space-y-0">
            <div class="details-item">
              <press-kit-rss :model="guest" entity="guest" />
            </div>
          </div>
        </div> -->

        <div v-if="section == 3">
          <div class="border-b-0 border-gray-300 mt-10 mx-13">
            <guest-booking-options :guest="guest" />
          </div>
        </div>

        <div v-if="section == 2" class="border-b border-gray-300 mt-10 mx-13 pb-10">
          <profile-pitch :isEnabled="pitchSettings.is_enabled" :model="guest" entity="guest"/>
        </div>

        <div v-if="section == 2">
          <div class="relative px-13">
            <div class="absolute mt-7 ml-28">
              <ToggleSwitch v-if="section == 2" :active="pitchSettings.questions" @toggled="togglePitchApplication"/>
            </div>
            <pitch-questions :isEnabled="pitchSettings.questions" :model="guest" entity="guest" />
          </div>
          <div class="w-7/8 h-0 mx-13 border-b-0 border-gray-300"></div>
        </div>

        <div v-if="section == 3">
          <div class="relative px-13">
            <div class="absolute mt-7 ml-28">
              <ToggleSwitch v-if="section == 3" :active="applicationEnabled" @toggled="toggleBookingApplication"/>
            </div>
            <booking-questions :isEnabled="applicationEnabled" :model="guest" entity="guest" />
          </div>
          <div class="w-7/8 h-0 mx-13 border-b-0 border-gray-300"></div>
        </div>

        <div v-if="section == 3" class="border-b last:border-b-0 border-gray-300 mt-10 mb-4 mx-13">
          <upsell-packages :isEnabled="upsellsEnabled" :model="guest" entity="guest" @toggleUpsells="toggleUpsells"/>
        </div>

        <div v-if="section == 5">
          <div class="mx-13" v-if="user.account_type == 'pro'">
            <guest-pro-settings />
          </div>
        </div>

        <!-- <div class="mt-20">
          <guest-availability />
        </div> -->

        <!-- <div v-if="section == 6">
          <div class="mt-8 mx-13" ref="linked-accounts">
            <guest-linked-accounts />
          </div>
        </div> -->

        <div v-if="section == 6">
          <div class="mt-14 mx-13 pb-10">
            <guest-booking-links :guest="guest" />
          </div>
        </div>

        <div v-if="section == 7">
          <div class="mt-20 mx-13">
            <delete-profile :model="guest" entity="guest" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as GuestModules from '@/components/details/guest'
  import * as PressKitModules from '@/components/details/press-kit'
  import DeleteProfile from '@/components/details/DeleteProfile'
  import ProfileNavigation from '@/components/details/ProfileNavigation'
  import BookingQuestions from '@/components/details/pro/BookingQuestions.vue'
  import PitchQuestions from '@/components/details/pro/PitchQuestions.vue'
  import UpsellPackages from '@/components/details/pro/UpsellPackages.vue'
  import ToggleSwitch from '@/components/shared/ToggleSwitch'

  export default {
    components: {
      ...GuestModules,
      ...PressKitModules,
      DeleteProfile,
      ProfileNavigation,
      BookingQuestions,
      PitchQuestions,
      UpsellPackages,
      ToggleSwitch
    },

    data() {
      return {
        working: false,
        section: '1',
        applicationEnabled: true,
        upsellsEnabled: false,
        bookingFlowPreferences: {},
        pitchSettings: {},
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      guest() {
        return this.$store.getters['guest/getGuest']
      },

      hash() {
        return this.$route.hash.replace('#', '')
      },

      sectionName() {
        switch (this.section) {
          case 1:
            return "Guest Details & Presskit";
          case 2:
            return "Pitch";
          case 3:
            return "Booking Flow Steps";
          case 4:
            return "Pro Stats";
          case 5:
            return "Pro Settings";
          case 6:
            return "Booking Links";
          case 7:
            return "Danger Zone";
          default:
            return "Guest Details & Presskit";
        }
      }
    },

    methods: {
      changeSection(index) {
        this.section = index;
      },

      togglePitch(state) {
        this.$store.dispatch('guest/updatePitchSettings', {
          modelId: this.guest.id,
          is_enabled: state,
        }).then(() => {
          this.pitchSettings.is_enabled = state;
          this.$toast.success(state ? 'Pitch enabled' : 'Pitch disabled');
        })
      },

      togglePitchApplication() {
        this.$store.dispatch('guest/updatePitchSettings', {
          modelId: this.guest.id,
          questions: !this.pitchSettings.questions,
        }).then(() => {
          this.pitchSettings.questions = !this.pitchSettings.questions;
          this.$toast.success(this.pitchSettings.questions ? 'Application enabled' : 'Application disabled');
        })
      },

      toggleBookingApplication() {
        this.bookingFlowPreferences.questions = !this.bookingFlowPreferences.questions;

        this.$store.dispatch('guest/updateBookingFlowPreferences', {guestId: this.user.guest.id, updatedPreferences: this.bookingFlowPreferences}).then(() => {
          this.applicationEnabled = this.bookingFlowPreferences.questions;
          this.$toast.success(this.applicationEnabled ? 'Application enabled' : 'Application disabled' )
        })
      },

      toggleUpsells() {

        this.bookingFlowPreferences.upsells = !this.bookingFlowPreferences.upsells;

        this.$store.dispatch('guest/updateBookingFlowPreferences', {guestId: this.user.guest.id, updatedPreferences: this.bookingFlowPreferences}).then(() => {
          this.upsellsEnabled = this.bookingFlowPreferences.upsells;
          this.$toast.success(this.upsellsEnabled ? 'Upsells enabled' : 'Upsells disabled' )
        })
      }
    },

    created() {
      this.$store.dispatch("auth/getUser").then(() => {
        this.$store.commit('guest/setGuest', this.user.guest)
        this.$store.commit('guest/setGuest', {
          links: {
            articles: [],
            podcasts: [],
            social_media: {
              facebook: null,
              instagram: null,
              linkedin: null,
              twitter: null,
              website: null,
              wikipedia: null,
              youtube: null,
              tiktok: null,
            },
            videos: [],
          }
        })

        if (! this.user.guest) {
          return this.$router.push({name: 'GuestCreate'})
        }

        this.$store
          .dispatch('guest/fetchPitchSettings', this.guest.id)
          .then(({ data }) => {
            this.pitchSettings = data.data
          });

        this.$store.dispatch('guest/fetchBookingFlowPreferences', this.user.guest.id).then((data) => {
          this.bookingFlowPreferences = data.data.data
          this.applicationEnabled = this.bookingFlowPreferences.questions;
          this.upsellsEnabled = this.bookingFlowPreferences.upsells;
        })

        if (! this.$route.hash.length) {
          return
        }

        setTimeout(() => {
          let $el = this.$refs[this.hash]

          if ($el) {
            $el.scrollIntoView({behavior: 'smooth'})
          }
        }, 1000)
      })
    },

    destroyed() {
      this.$store.commit('guest/clearGuest')
    }
  }
</script>

<style scoped>
.details-and-presskit > div {
  @apply mb-4 md:mb-auto border-b border-gray-300;
}

.details-item {
  @apply py-1 md:py-6 mx-13;
}
</style>
