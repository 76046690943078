<template>
  <div>
    <portal to="modal">
      <div style="background-color: rgba(32, 32, 32, 0.66);"
          class="fixed z-50 inset-0 flex flex-col items-center justify-start w-screen px-6 lg:px-0 pt-32 pb-10 h-screen overflow-scroll">
        <button @click="closeModal" class="absolute right-0 top-0 mt-12 mr-12 text-white">
          <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.75 16.25L16.25 48.75" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M16.25 16.25L48.75 48.75" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
        <div class="w-full lg:w-4/12">
          <div class="w-full bg-white rounded-lg p-8">
            <!-- Modal header-->
            <div class="w-full">
              <h2 class="font-bold text-4xl text-center">Edit Availability</h2>
            </div>
            <!-- Modal body-->
            <div class="my-10">
              <!--Intervals -->
              <div v-if="!form.intervals.length" class="h-12 border border-dashed rounded-full flex items-center justify-center">
                <span class="text-sm text-gray-600">Unavailable</span>
              </div>
              <div v-else class="flex flex-wrap">
                <div class="w-5/12">
                  <span class="mb-3">From</span>
                </div>
                <div class="w-5/12 pl-3">
                  <span class="mb-3">To</span>
                </div>
              </div>
              <div class="flex items-end mb-3 time-intervals" v-for="(interval, index) in form.intervals" :key="`interval-${index}`">
                <div class="w-5/12 mr-3">
                  <div
                    class="flex items-center bg-transparent rounded-full bg-softGray py-2 px-5 border border-transparent"
                    :class="{'border-red-500': checkOverlaps(interval.from, index)}"
                  >
                    <ClockIcon class="flex-shrink-0"/>
                    <datetime
                      title="From"
                      type="time"
                      v-model="interval.from"
                      :minute-step="15"
                      :use12-hour="true"
                      format="h:mm a"
                      :phrases="{ok: 'Apply', cancel: 'Cancel'}"
                      input-class="pl-4 w-auto bg-transparent select-none cursor-pointer"
                      :max-datetime="interval.to"
                      @input="checkOverlaps($event, index)"
                    />
                  </div>
                </div>
                <div class="w-5/12 mr-3">
                  <div
                    class="flex items-center bg-transparent rounded-full bg-softGray py-2 px-5 border border-transparent"
                    :class="{'border-red-500': checkOverlaps(interval.to, index)}"
                  >
                    <ClockIcon class="flex-shrink-0"/>
                    <datetime
                      title="To"
                      type="time"
                      v-model="interval.to"
                      :minute-step="15"
                      :use12-hour="true"
                      format="h:mm a"
                      :phrases="{ok: 'Apply', cancel: 'Cancel'}"
                      input-class="pl-4 w-auto bg-transparent select-none cursor-pointer"
                      :min-datetime="interval.from"
                      @input="checkOverlaps($event, index)"
                    />
                  </div>
                </div>
                <div class="w-2/12">
                  <button class="rounded-full bg-softGray flex justify-center items-center p-2" @click.prevent="removeInterval(index)">
                    <CrossIcon :classes="'w-6'"/>
                  </button>
                </div>
              </div>
              <div v-if="overlappingError" class="my-2 bg-red-100 rounded-lg p-2" role="alert">
                <span class="text-sm text-red-700">
                  You have overlapping intervals
                </span>
              </div>
              <!--Unavailable -->
              <div v-if="unavailable">
                <div class="border border-dashed rounded-full px-4 py-3 flex justify-center items-center mb-8">
                  <span>Unavailable</span>
                </div>
              </div>
              <!-- Actions-->
              <div class="mb-8">
                <!--Add intervals -->
                <button @click.prevent="addInterval()" class="text-pink-500 underline mb-5 mt-2">Add Interval +</button>
                <!--Make Unavailable -->
                <button v-if="form.intervals.length" @click.prevent="removeAllIntervals()" type="button" class="py-3 px-8 border border-pink-500 rounded-full text-pink-500 block">
                  Set as Unavailable
                </button>
              </div>
              <!--Radio buttons-->
              <div v-if="rule.type == 'wday'" class="grid grid-cols-1 row-gap-3 mb-10">
                <label class="cursor-pointer">
                  <input class="form-radio mr-3" type="radio" name="role_type" value="wday" v-model="form.type" @change="setRoleTypeValue('wday')">
                  <span>Repeat every <span class="capitalize">{{rule.wday}}</span></span>
                </label>
                <label class="cursor-pointer">
                  <input class="form-radio mr-3" type="radio" name="role_type" value="custom" v-model="form.type">
                  <span>Repeat on custom days</span>
                </label>
              </div>
              <!--Custom days-->
              <div v-if="form.type == 'custom'">
                <div v-if="rule.type == 'wday'" class="inline-flex mb-10 rounded-full bg-softGray space-x-0">
                  <label class="py-3 rounded-full px-5 relative z-10 cursor-pointer" :class="{'bg-lightBlack text-white': form.repeating.type == 'wdays'}">
                    <span>Days of the week</span>
                    <input type="radio" name="repeatingType" value="wdays" class="hidden" v-model="form.repeating.type">
                  </label>
                  <label class="py-3 rounded-full px-8 -ml-5 cursor-pointer" :class="{'bg-lightBlack text-white': form.repeating.type == 'dates'}">
                    <span>Specific dates</span>
                    <input type="radio" name="repeatingType" value="dates" class="hidden" v-model="form.repeating.type">
                  </label>
                </div>

                <div class="bg-softGray p-10 rounded grid grid-cols-1 row-gap-4" v-if="form.repeating.type == 'wdays'">
                  <label
                      v-for="(wday, index) in 7" :key="`wday-${index}`"
                      class="flex items-center cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        class="form-checkbox"
                        :class="index == day.weekday() ? 'text-gray-400' : 'text-pink-500'"
                        name="wday"
                        :value="index"
                        v-model="form.repeating.wdays"
                        :disabled="index == day.weekday()"
                      >
                      <span class="ml-3">{{ moment.weekday(index).format('dddd') }}</span>
                    </label>
                </div>

                <div v-if="form.repeating.type == 'dates'">
                  <div class="flex justify-center p-6">
                    <date-picker mode="multiple" v-model="repeatingDates" is-inline :min-date='new Date()' :is-required="true"/>
                  </div>
                </div>
              </div>
            </div>
            <!--Modal footer-->
            <div class="w-full flex justify-between items-center">
              <button @click="closeModal" class="uppercase text-pink-500 font-semibold">Cancel</button>
              <button class="btn disabled:opacity-50" type="submit" @click.prevent="saveRule" :disabled="overlappingError">
                <span v-if="loading">
                  <loading-icon class="h-5 w-5 mr-4"/>
                </span>
                <span v-else>Save and Apply</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
  import { Datetime } from 'vue-datetime'
  import moment from 'moment'
  import ClockIcon from "../svgs/ClockIcon";
  import CrossIcon from "../svgs/CrossIcon";
  import DatePicker from 'v-calendar/lib/components/date-picker.umd'
  import 'vue-datetime/dist/vue-datetime.css'

  export default {
    name: "AvailabilityModal",
    components: { ClockIcon, CrossIcon, DatePicker, Datetime },
    props: ['rule'],
    data() {
      return {
        unavailable: false,
        customDays: false,
        loading: false,
        repeatingDates: [],
        form: {
          type: null,
          wday: null,
          date: null,
          intervals: [],
          repeating: {
            type: 'wdays',
            dates: [],
          }
        },
      }
    },

    watch: {
      repeatingDates(dates) {
        this.form.repeating.dates = dates.map(date => moment(date).format('YYYY-MM-DD'))
      },
    },

    computed: {
      day() {
        return moment().isoWeekday(this.rule.wday)
      },

      overlappingError() {
        return this.form.intervals.some((interval, index) => {
          return this.checkOverlaps(interval.from, index)
            || this.checkOverlaps(interval.to, index)
        })
      }
    },

    methods: {
      addInterval() {
        this.form.intervals.push({from: null, to: null})
      },
      closeModal() {
        this.$emit('closeAvailabilityModal');
      },

      removeAllIntervals() {
        this.form.intervals = []
      },

      removeInterval(index) {
        this.form.intervals.splice(index, 1)
      },

      setRoleTypeValue() {
          this.form.wday = this.rule.wday
      },

      saveRule() {
        if (this.form.repeating.type == 'dates') {
          this.form.repeating.dates = this.form.repeating.dates.map(date => moment(date).format('YYYY-MM-DD'))
        }

        if (this.form.repeating.type == 'wdays') {
          this.form.repeating.wdays = this.form.repeating.wdays.map(wday => moment().weekday(wday).format('dddd').toLowerCase())
        }

        if (this.form.intervals.length) {
          this.form.intervals = this.form.intervals.map(interval => {
            return {
              from: moment(interval.from).format('HH:mm'),
              to: moment(interval.to).format('HH:mm'),
            }
          })
        }

        this.$emit('save-rule', this.form)
      },

      checkOverlaps(date, intervalIndex) {
        if (this.form.intervals.length < 2 || ! date) {
          return false
        }

        return this.form.intervals.some((interval, index) => {
          if (index == intervalIndex) {
            return false
          }

          return moment(date).isBetween(interval.from, interval.to)
        })
      }
    },

    mounted() {
      if (this.rule && this.rule.type == 'wday') {
        this.form.repeating.wdays = [this.day.weekday()];
        this.form.intervals = JSON.parse(JSON.stringify(this.rule.intervals))
        this.form.type = this.rule.type
        this.form.wday = this.rule.wday
      }

      if (this.rule && this.rule.type == 'date') {
        this.form.repeating.type = 'dates';
        this.repeatingDates = [moment(this.rule.date).toDate()]
        this.form.intervals = JSON.parse(JSON.stringify(this.rule.intervals))
        this.form.type = 'custom'
      }

      this.moment = moment()

      this.$watch('rule', rule => {
        this.form.type = rule.type
        this.form.wday = rule.wday || null
        this.form.date = rule.date || null
        this.form.intervals = JSON.parse(JSON.stringify(rule.intervals))
      }, { deep: true })
    }
  }
</script>

<style>
.time-intervals .vdatetime-popup {
  @apply overflow-hidden rounded-lg;
}
.time-intervals .vdatetime-popup__header {
  background: linear-gradient(
    to right,
    #8003d0 1%,
    #dc3cf6 100%
  );
}
.time-intervals .vdatetime-popup__title {
  @apply font-bold text-3xl mb-0;
}
.time-intervals .vdatetime-time-picker__item--selected {
  @apply text-pink-500;
}
.time-intervals .vdatetime-popup__actions__button--cancel {
  @apply text-gray-500;
}
.time-intervals .vdatetime-popup__actions {
  @apply pt-3;
}
.time-intervals .vdatetime-popup__actions__button--confirm {
  /*@apply text-pink-500 font-semibold;*/
  @apply h-10 rounded-full px-10 text-white;
  background: linear-gradient(
    to right,
    #8003d0 1%,
    #dc3cf6 100%
  );
}
</style>
