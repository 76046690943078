<template>
  <div>
    <div>
      <h3 class="text-3xl text-black font-bold">
        <a name="availability">Pricing</a>
      </h3>
      <p class="text-gray-500 mt-2">Select pricing and time limits.</p>
    </div>
    <div class="mt-12 w-8/12">
      <availability ref="availability" />
    </div>

    <div class="flex items-center">
      <button @click.prevent="saveAvailability" type="button" class="h-12 rounded-full px-10 py-3 bg-indigo-gradiant text-white" :class="{'opacity-50': working}" :disabled="working">
        <span v-if="working">
          <loading-icon class="h-2 px-10" />
        </span>
        <span v-else>Save availability</span>
      </button>

      <transition name="simple-fade" appear>
        <span v-if="saved" class="ml-4 text-green-600">
          Saved!
        </span>
      </transition>

      <transition name="simple-fade" appear>
        <span v-if="error.length" class="ml-4 text-red-600">
          {{ error }}
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
  import Availability from '@/components/register/Availability'
  export default {
    components: {
      Availability,
    },

    data() {
      return {
        working: false,
        error: '',
        saved: false,
      }
    },

    computed: {
      settings() {
        return this.$store.getters['guest/availability/settings']
      },

      rules() {
        return this.$store.getters['guest/availability/rules']
      },

      guest() {
        return this.$store.getters['guest/getGuest']
      },
    },

    methods: {
      scrollToFirstError() {
        const el = this.$el.querySelector('.form-error:first-of-type')

        if (! el) {
          return
        }

        el.parentElement.scrollIntoView()
      },

      async saveAvailability() {
        let confirmed = await this.confirmLogin()

        if (! confirmed) {
          return
        }

        this.error = ''
        this.working = true

        let form = this.$refs.availability.$refs.availabilityForm

        if (! form.validate()) {
          this.error = 'Please correct the form errors'
          this.working = false
          this.scrollToFirstError()
          return
        }

        this.$store
          .dispatch('guest/availability/update', {
            ...this.settings,
            rules: this.rules,
            guestId: this.guest.id,
          })
          .then(() => {
            this.saved = true
            setTimeout(() => this.saved = false, 3000)
          })
          .catch(error => {
            form.setErrors(error.response.data.errors)
            this.error = 'Please correct the form errors'
            this.scrollToFirstError()
          })
          .finally(() => this.working = false)
      }
    }
  }
</script>
