<template>
  <div class="flex flex-col md:flex-row md:justify-between mt-3">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Social Media</span>
    </div>
    <div v-if="guest.links" class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <div v-if="guest.links.social_media" class="mb-6">
          <div class="mt-1">
            <span class="flex text-grayDark font-light mt-3 text-base" v-for="(link, index) in guest.links.social_media" :key="`link-${index}`">
              <span class="items-center capitalize mr-2 text-blue-800 font-semibold">{{index}}: </span>
              <span class="h-7 overflow-hidden break-all">{{ link ? link : '-' }}</span>
            </span>
          </div>
        </div>
        <Edit @edit="edit = true" class="mb-40"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="linksForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <div v-for="(link, index) in form.links.social_media" :key="`link-${index}`">
                <ValidationProvider :rules="socialMediaRules(index)" name="Link" :vid="form.links.social_media[index]" v-slot="{ errors }" class="w-full">
                  <span class="py-1 flex flex-col">
                    <p class="flex items-center capitalize ml-5 text-grayDark font-light">{{index}}</p>
                      <input id="link-social_media" type="text" class="bg-white border-solid border-2 border-gray-400 rounded-3xl py-3 px-6" v-model="form.links.social_media[index]">
                  </span>
                  <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div v-else class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <loading-icon class="h-5 w-5 mr-4"/>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Edit from "@/components/shared/Edit"
  import SaveOrCancel from "@/components/shared/SaveOrCancel";


  export default {
    components: { Edit, SaveOrCancel },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          links: {
            social_media: {
              facebook: null,
              instagram: null,
              linkedin: null,
              twitter: null,
              website: null,
              wikipedia: null,
              youtube: null,
              tiktok: null,
            }
          },
        },
      }
    },

    watch: {
      edit(edit) {
        if (edit) return;
        this.fetchGuestLinks();
      }
    },

    mounted() {
      this.form.name = this.user.guest.name
      this.form.id = this.user.guest.id
      this.fetchGuestLinks();
    },

    methods: {
      fetchGuestLinks() {
        const params = {
          id: this.user.guest.id,
          excludes: 'podcasts_parsed,videos_parsed,articles_parsed',
          type: 'social_media'
        }
        this.$store.dispatch('guest/fetchGuestLinks', params)
          .then(() => this.form.links.social_media = this.guest.links.social_media)
      },

      getValidUrl(url = "") {
        let newUrl = window.decodeURIComponent(url);
        newUrl = newUrl.trim().replace(/\s/g, "");

        if(/^(:\/\/)/.test(newUrl)){
            return `http${newUrl}`;
        }
        if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
            return `http://${newUrl}`;
        }

        return newUrl;
      },
      update() {
        this.working = true

        const socialNetworks = this.form.links.social_media;
        for (const socialNetwork in socialNetworks) {
          if (socialNetworks[socialNetwork] != null  && socialNetworks[socialNetwork] != '') {
            socialNetworks[socialNetwork] = this.getValidUrl(socialNetworks[socialNetwork])
          }
        }

        this.$store.dispatch('guest/updateGuest', this.form)
          .then(() => {
            this.working = false;
            this.edit = false;
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.linksForm.setErrors(error.response.data.errors)
            }
          })
      },

      socialMediaRules(socialNetwork) {
        let hasDomain = `hasDomain:${socialNetwork}`;
        return `url${socialNetwork != 'website' ? '|' + hasDomain : ''}`;
      },
    },

    computed: {
      ...mapState({
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),
    },
  }
</script>