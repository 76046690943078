<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Profile color</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span>
          <div v-if="profile.color" class="h-5 w-5 rounded-md" :style="`background-color: ${profile.color}`"></div>
          <span v-else class="text-blue-800 font-semibold">Default</span>
        </span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="nameForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider vid="color" name="Color">
                <sketch-picker v-model="colors" />
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import { Sketch } from 'vue-color'
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel, 'sketch-picker': Sketch},

    props: {
      model: Object,
      entity: String,
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          color: null,
        },
        colors: null
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.color = this.colors.hex
      },

      colors(colors) {
        this.form.color = colors.hex
      }
    },

    mounted() {
      this.form.color =  this.model.color
      this.form.id = this.model.id
      this.colors = this.profile.color ? this.profile.color : '#B503D0'
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch(this.updateAction, this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.nameForm.setErrors(error.response.data.errors)
            }
          })
      }
    },

    computed: {
      ...mapState({
        show: state => state.shows.show,
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),

      profile() {
        return this.entity == 'guest' ? this.guest : this.show
      },

      updateAction() {
        return this.entity == 'guest' ? 'guest/updateGuest' : 'shows/updateShow'
      },
    },
  }
</script>