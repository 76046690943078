<template>
  <div>
    <div>
      <p class="text-gray-500 align-center w-full">
        Generate booking links to share with your prefered hosts to allow them to book you for free
      </p>
    </div>
    <div class="mt-5 md:mt-12">
      <div v-if="working" class="py-6 flex items-center justify-center">
        <loading-icon class="h-2 text-pink-500" />
      </div>

      <ul v-if="! working" class="space-y-4">
        <li v-if="bookingCodes.data.length">
          <button @click.prevent="createBookingLink()" type="button" class="mb-4 text-sm font-medium text-pink-500  underline">
            Create new link +
          </button>
        </li>
        <li v-if="! bookingCodes.data.length">
          <div class="h-40 border-2 border-dashed border-gray-300 px-4 py-4 sm:px-6 flex flex-col justify-center items-center rounded-xl">
            <span class="text-sm font-medium text-gray-500">No booking links created</span>
            <button @click.prevent="createBookingLink()" type="button" class="mt-8 text-sm font-medium text-pink-500  underline">
              Create link +
            </button>
          </div>
        </li>
        <li v-for="code in bookingCodes.data" :key="`code-row-${code.id}`">
          <div
            class="block focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out rounded-md overflow-hidden"
            :class="{'bg-green-100': code.isUsed, 'bg-red-100': ! code.isUsed && code.isExpired, 'bg-gray-50': code.isValid}"
          >
            <div class="px-4 py-4 sm:px-6 flex flex-col sm:flex-row sm:justify-between sm:items-end">
              <div>
                <div class="text-sm leading-5 font-medium text-indigo-600 truncate sm:whitespace-normal">
                  {{ buildUrl(code.code) }}
                </div>
                <div class="mt-2 flex items-center space-x-4 lg:space-x-6">
                  <div class="flex items-center text-xs leading-5 text-gray-700">
                    <span class="flex flex-col">
                      <span class="text-gray-500 opacity-75">Created on</span>
                      <time :datetime="code.created_at">
                        {{ moment(code.created_at).format('MMM DD, Y') }}
                      </time>
                    </span>
                  </div>
                  <template v-if="code.isUsed">
                    <div class="flex items-center text-xs leading-5 text-gray-700">
                      <span class="flex flex-col">
                        <span class="text-gray-500 opacity-75">Used on</span>
                        <time :datetime="code.used_at">
                          {{ moment(code.used_at).format('MMM DD, Y') }}
                        </time>
                      </span>
                    </div>
                  </template>
                  <template v-else-if="! code.isExpired">
                    <div class="flex items-center text-xs leading-5 text-gray-700">
                      <span class="flex flex-col">
                        <span class="text-gray-500 opacity-75">Expires on</span>
                        <time :datetime="code.valid_until">
                          {{ moment(code.valid_until).format('MMM DD, Y') }}
                        </time>
                      </span>
                    </div>
                  </template>
                  <template v-else-if="code.isExpired">
                    <div class="flex items-center text-xs leading-5 text-gray-700">
                      <span class="flex flex-col">
                        <span class="text-gray-500 opacity-75">Expired on</span>
                        <time :datetime="code.valid_until">
                          {{ moment(code.valid_until).format('MMM DD, Y') }}
                        </time>
                      </span>
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="code.isUsed && code.booking_id">
                <div class="flex items-center text-sm leading-5 text-gray-700">
                  <router-link
                    :to="`/bookings/${code.booking_id}`"
                    class="text-pink-500 hover:text-pink-600 hover:underline whitespace-no-wrap"
                  >
                    View Booking
                  </router-link>
                </div>
              </div>
              <div v-if="! code.isUsed" class="mt-4 sm:mt-0 flex items-center space-x-2">
                <template v-if="! code.isExpired">
                  <button
                    type="button"
                    class="text-sm text-pink-500 active:text-green-500 transition duration-150 ease-in-out"
                    v-clipboard:copy="buildUrl(code.code)"
                    v-clipboard:success="onCopySuccess"
                    v-clipboard:error="onCopyError"
                  >
                    Copy
                  </button>
                  <span class="text-gray-400">&bull;</span>
                </template>
                <alert-dialog
                  confirm="Delete Booking Link"
                  @confirm="deleteBookingCode($event, code)"
                  class="text-sm text-pink-500"
                >
                  <span>Delete</span>
                  <span slot="title">Delete Booking Link?</span>
                  <template v-slot:content>
                    <div>Are you sure you want to cancel this booking link? This action cannot be undone.</div>
                  </template>
                </alert-dialog>
              </div>
            </div>
          </div>
        </li>
        <li v-if="bookingCodes.data.length < bookingCodes.total">
          <button @click.prevent="showMore()" type="button" class="mb-4 text-sm font-medium text-pink-500  underline">
            Show more
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import ClipboardJS from 'clipboard'
  import moment from 'moment'
  import api from '@/api'
  import AlertDialog from '@/components/shared/AlertDialog'
  export default {
    props: {
      guest: Object,
    },

    components: {
      AlertDialog
    },

    data() {
      return {
        bookingCodes: {
          data: []
        },
        per_page: 5,
        working: false,
      }
    },

    computed: {
      appUrl() {
        return process.env.VUE_APP_URL
      }
    },

    methods: {
      buildUrl(code) {
        return `${this.appUrl}/guests/${this.guest.id}/book?code=${code}`
      },

      fetchBookingCodes() {
        this.working = true

        api.get(`/guests/${this.guest.id}/booking-codes?per_page=${this.per_page}`)
          .then(({data}) => {
            this.bookingCodes = data
          })
          .catch(() => {
            //
          })
          .finally(() => this.working = false)
      },

      moment(date) {
        return moment(date)
      },

      async createBookingLink() {
        this.working = true

        await api.post(`/guests/${this.guest.id}/booking-codes`)
          .catch(() => {
            //
          })

        this.page = 1
        this.fetchBookingCodes()
      },

      onCopySuccess(event) {
        event.trigger.innerText = 'Copied!'
        event.trigger.classList.remove('text-pink-500')
        event.trigger.classList.add('text-green-500')

        setTimeout(() => {
          event.trigger.innerText = 'Copy'
          event.trigger.classList.remove('text-green-500')
        event.trigger.classList.add('text-pink-500')
        }, 3000)
      },

      onCopyError(code) {
        alert(`Failed to copy to clipboard. Booking link is: ${this.buildUrl(code)}`)
      },

      async deleteBookingCode(callback, code) {
        let index = this.bookingCodes.data.findIndex(c => c.id == code.id)
        this.bookingCodes.data.splice(index, 1)

        await api.delete(`/guests/${this.guest.id}/booking-codes/${code.code}`)

        callback.call()

        this.fetchBookingCodes()
      },

      showMore() {
        this.per_page = this.per_page + 5

        this.fetchBookingCodes()
      }
    },

    created() {
      this.fetchBookingCodes()
    },

    mounted() {
      this.clipboard = new ClipboardJS('.js-copy-booking-link');
    }
  }
</script>
