<template>
  <div class="flex flex-col md:flex-row md:justify-between mt-6">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Podcast Appearances</span>
    </div>
    <div v-if="guest.links" class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <div v-if="guest.links.podcasts" class="mb-6">
          <div class="mt-1">
            <span class="flex text-sm text-grayDark font-light" v-for="(link, index) in guest.links.podcasts" :key="`link-${index}`">
              <span class="h-4 mb-4 overflow-hidden break-all">{{ link ? link : '-' }}</span>
            </span>
            <p v-if="!guest.links.podcasts.length" class="text-gray-500">
              No links added.
            </p>
          </div>
        </div>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="linksForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <AddLinks :links="form.links.podcasts" :withTitle="false" shouldValidate="podcast"/>
            </div>

            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div v-else class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <loading-icon class="h-5 w-5 mr-4"/>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import AddLinks from "@/components/shared/AddLinks"
  import Edit from "@/components/shared/Edit"
  import SaveOrCancel from "@/components/shared/SaveOrCancel";


  export default {
    components: {AddLinks, Edit, SaveOrCancel},

    data() {
      return {
        working: false,
        edit: false,
        form: {
          links: {
            podcasts: [],
          },
        },
      }
    },

    watch: {
      edit(edit) {
        if (edit) return;
        this.fetchGuestLinks();
      }
    },

    mounted() {
      this.form.name = this.user.guest.name
      this.form.id = this.user.guest.id
      this.fetchGuestLinks();
    },

    methods: {
      fetchGuestLinks() {
        const params = {
          id: this.user.guest.id,
          excludes: 'podcasts_parsed,videos_parsed,articles_parsed',
          type: 'podcasts'
        }
        this.$store.dispatch('guest/fetchGuestLinks', params)
          .then(() => this.form.links.podcasts = this.guest.links.podcasts)
      },

      getValidUrl(url = "") {
        let newUrl = window.decodeURIComponent(url);
        newUrl = newUrl.trim().replace(/\s/g, "");

        if(/^(:\/\/)/.test(newUrl)){
            return `http${newUrl}`;
        }
        if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
            return `http://${newUrl}`;
        }

        return newUrl;
      },
      update() {
        this.working = true

        let links = [];
        this.form.links.podcasts.forEach(link => {
          links.push(this.getValidUrl(link))
        });
        this.form.links.podcasts = links;

        this.$store.dispatch('guest/updateGuest', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.linksForm.setErrors(error.response.data.errors)
            }
          })

        this.$forceUpdate();
      },
    },

    computed: {
      ...mapState({
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),
    },
  }
</script>