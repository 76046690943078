<template>
	<div>
		<ValidationObserver ref="availabilityForm" v-slot="{ handleSubmit }">
			<form method="post" @submit.prevent="handleSubmit(saveAvailability)">
				<!-- Time Slot-->
				<div class="mb-20">
					<label class="block mb-5 font-semibold">Time Slots</label>
          <div v-if="! settings.interview_durations.length" class="h-12 border border-dashed rounded-full flex items-center justify-center -mt-2">
            <span class="text-sm text-gray-600">No slots added</span>
          </div>
					<div v-else v-for="(slot, index) in settings.interview_durations" :key="`slot-${index}`" class="flex items-start mb-5">
            <div class="flex items-center">
              <div>
                <ValidationProvider rules="required" :name="`Time slot duration ${index}`" v-slot="{ errors }">
                  <div
                    class="bg-transparent rounded-full bg-gray-50 py-2 px-5 flex items-center "
                    :class="{'border border-red-500': errors.length}"
                  >
                    <ClockIcon class="mr-3 flex-shrink-0"/>
                    <select class="bg-transparent" v-model="slot.duration">
                      <option
                        v-for="duration in availableDurations"
                        :value="duration"
                        :key="`duration-option-${duration}`"
                        :hidden="selectedDurations.includes(duration) && duration != slot.duration"
                      >{{ duration }}</option>
                    </select>
                  </div>
                </ValidationProvider>
              </div>

              <div class="ml-2">
                <span>minutes</span>
              </div>
            </div>

            <div class="ml-6 xl:ml-12 flex items-center">
              <div>
                <ValidationProvider name="Price" v-slot="{ errors }">
                  <div
                    class="bg-transparent rounded-full bg-gray-50 py-2 px-5 flex items-center w-32"
                    :class="[{'border border-red-500': errors.length}]"
                  >
                    <DollarSignIcon class="mr-3 flex-shrink-0"/>
                    <input
                      id="price"
                      type="number"
                      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                      class="bg-transparent w-full"
                      v-model="slot.price"
                    >
                  </div>
                  <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <div class="h-10 flex items-center">
              <label class="ml-6 flex">
                <input type="checkbox" class="form-checkbox h-4 w-4 sm:h-5 sm:w-5 text-pink-500" :checked="slot.price == 0" @change="freeSlot($event.target.checked, index, slot.price)">
                <span class="ml-2">Free</span>
              </label>
            </div>

						<div v-if="index > 0" class="ml-6">
							<button @click.prevent="removeInterviewDuration(index)" class="rounded-full bg-gray-50 h-10 w-10 flex justify-center items-center">
								<CrossIcon/>
							</button>
						</div>
					</div>
					<button @click.prevent="addInterviewDuration()" class="text-pink-500 underline mt-5 block">Add Additional Time Slots +</button>
				</div>
        <template v-if="enableAvailabilityRules">
          <!-- When can people book you?-->
					<div class="mb-20">
						<label class="block mb-5 font-semibold">When can people book you?</label>
						<ValidationProvider rules="required" vid="type" name="Availability type" v-slot="{ errors }">
							<div class="grid grid-cols-1 row-gap-3">
								<div>
									<input class="form-radio text-pink-500 w-6 h-6 mr-3" type="radio" name="availability_type" id="type-unlimited" value="unlimited" v-model="settings.type">
									<label for="type-unlimited">Indefinitely</label>
								</div>
								<div>
									<input class="form-radio text-pink-500 w-6 h-6 mr-3" type="radio" name="availability_type" id="type-rolling" value="rolling" v-model="settings.type">
									<label class="mr-3" for="type-rolling">Over the next </label>
                  <ValidationProvider rules="required|min_value:1" vid="rolling_days" name="Rolling Days" v-slot="{ errors }">
                    <input class="form-error bg-transparent rounded-full bg-gray-50 py-2 px-5 w-20 mr-3 border border-transparent" type="text" v-model="settings.rolling_days" :class="{'border-red-500': errors.length}">
                    <span>days</span>
                  </ValidationProvider>
								</div>
								<div>
									<input class="form-radio text-pink-500 w-6 h-6 mr-3" type="radio" name="availability_type" id="type-range" value="range" v-model="settings.type">
									<label for="type-range">During a specific date range</label>
								</div>
							</div>
							<p class="form-error text-red-500 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
						</ValidationProvider>
						<div v-if="settings.type == 'range'" class="mt-8">
							<label class="block text-sm text-indigo-500 mb-0" for="name">Select a date range</label>
							<div class="mt-1 flex items-center justify-between -mx-3">
								<div class="flex-1 mx-3">
									<ValidationProvider rules="required" vid="start_date" name="Start date" v-slot="{ errors }">
										<input type="date" class="form-input w-full" name="start_date" v-model="settings.start_date">
										<p class="form-error text-red-500 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
									</ValidationProvider>
								</div>
								<div class="flex-1 mx-3">
									<ValidationProvider rules="required" vid="end_date" name="End date" v-slot="{ errors }">
										<input type="date" class="form-input w-full" name="end_date" v-model="settings.end_date">
										<p class="form-error text-red-500 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
									</ValidationProvider>
								</div>
							</div>
						</div>
					</div>
          <!-- Set Calendar-->
          <div>
            <label class="block mb-5 font-semibold">Set calendar</label>
            <div class="flex flex-col">
              <div class="flex items-center justify-between mb-6" v-for="(rule, index) in weekDayRules" :key="index">
                <span class="mr-8">{{toStartCase(rule.wday)}}</span>
                <button class="bg-transparent rounded-xl bg-softGray py-2 px-5 flex items-center" type="button" @click.prevent="openModal(rule)">
                  <div v-if="! rule.intervals.length" class="bg-transparent w-48 cursor-pointer">
                    Unavailable
                  </div>
                  <div v-else class="bg-transparent w-48 cursor-pointer flex flex-col">
                    <span v-for="(interval, index) in rule.intervals" :key="`interval-${index}`">
                      {{ showTimeInterval(interval) }}
                    </span>
									</div>
									<EditIcon/>
                </button>
              </div>
            </div>
          </div>
          <div v-if="dateRules.length" class="mt-6">
            <label class="block font-semibold mb-4">Custom Days</label>
            <div class="flex flex-col">
              <div class="flex items-center justify-between mb-6" v-for="(rule, index) in dateRules" :key="index">
                <span class="mr-8">{{ moment(rule.date).format('MMMM Do, YYYY') }}</span>
                <span class="bg-transparent rounded-xl bg-softGray py-2 px-5 flex items-center">
                  <button class="flex items-center" type="button" @click.prevent="openModal(rule)">
                      <div v-if="! rule.intervals.length" class="bg-transparent w-48 cursor-pointer">
                        Unavailable
                      </div>
                      <div v-else class="bg-transparent w-40 cursor-pointer flex flex-col">
                        <span v-for="(interval, index) in rule.intervals" :key="`interval-${index}`">
                          {{ showTimeInterval(interval) }}
                        </span>
                      </div>
                      <EditIcon/>
                  </button>
                  <alert-dialog
                    class="ml-3"
                    confirm="Delete Custom Day"
                    confirming="Deleting"
                    @confirm="deleteRule($event, rule)"
                  >
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    <template #title>Delete Custom Day?</template>
                    <template #content>
                      <div>Are you sure you want to delete this custom day availability?</div>
                    </template>
                  </alert-dialog>
                </span>
              </div>
            </div>
          </div>
        </template>
			</form>
		</ValidationObserver>
		<AvailabilityModal
			v-if="showModal"
			@closeAvailabilityModal="showModal = false"
			:rule="selectedRule"
			@save-rule="parseRules"
		/>
	</div>
</template>

<script>
  import moment from 'moment'
	import { mapState } from "vuex";
  import { startCase } from 'lodash'
  import ClockIcon from "../svgs/ClockIcon";
  import DollarSignIcon from "../svgs/DollarSignIcon";
  import CrossIcon from "../svgs/CrossIcon";
  import EditIcon from "../svgs/EditIcon";
	import AvailabilityModal from "./AvailabilityModal";
  import AlertDialog from '@/components/shared/AlertDialog'

	export default {
		name: "Availability",
		components: { ClockIcon, DollarSignIcon, CrossIcon, EditIcon, AvailabilityModal, AlertDialog },
		data() {
      return {
        enableAvailabilityRules: false,
				showModal: false,
				selectedRule: {}
      }
		},

		computed: {
			...mapState({
					availabilityModal: state => state.availabilityModal,
			}),

      availableDurations() {
        return this.$store.getters['guest/availability/availableDurations']
      },

			settings: {
        get() {
          return this.$store.getters['guest/availability/settings']
        },
        set(value) {
          this.$store.commit('guest/avaiability/setSettings', value)
        }
      },

			rules: {
        get() {
          return this.$store.getters['guest/availability/rules']
        },
        set(value) {
          this.$store.commit('guest/avaiability/setRules', value)
        }
      },

      weekDayRules() {
        return this.rules.filter(rule => rule.type == 'wday')
      },

      dateRules() {
        return this.rules.filter(rule => rule.type == 'date')
      },

			user() {
        return this.$store.getters['auth/getUser']
      },

      guest() {
        return this.$store.getters['guest/getGuest']
      },

      selectedDurations() {
        return this.settings.interview_durations.map(slot => slot.duration)
      },
		},

		methods: {
			addInterviewDuration() {
        this.settings.interview_durations.push({
          duration: null,
          price: 10
        })
			},

      removeInterviewDuration(index) {
        this.settings.interview_durations.splice(index, 1)
      },

			getAvailability() {
        this.$store.dispatch('guest/availability/fetch', { guestId: this.guest.id })
          .catch(error => {
            if (error.response) {
              console.log(error.response)
            }
          })
			},

			openModal(rule) {
				this.showModal = true;
				this.selectedRule = rule;
			},

			toStartCase(string) {
        return startCase(string)
			},

			showTimeInterval(interval) {
        let from = moment(interval.from, ['HH:mm', moment.ISO_8601]).format('h:mm A')
        let to = moment(interval.to, ['HH:mm', moment.ISO_8601]).format('h:mm A')

				return `${from} - ${to}`
			},

			findRule(payload) {
        if (payload.type == 'wday') {
          return this.rules.find(rule => rule.type == 'wday' && rule.wday == payload.wday)
        }

        return this.rules.find(rule => rule.type == 'date' && rule.date == payload.date)
      },

			updateRule(payload) {
        let rule = this.findRule(payload)

        // if rule exists, update it
        if (typeof rule != 'undefined') {
          rule.intervals = this.$set(rule, 'intervals', payload.intervals)
          return
        }

        // create wday rule
        if (payload.type == 'wday') {
          this.rules.push({
            type: 'wday',
            wday: payload.wday,
            intervals: [...payload.intervals]
          })

          return
        }

        // create date rule
        this.rules.push({
          type: 'date',
          date: payload.date,
          intervals: [...payload.intervals]
        })

        return
      },

			parseRules(payload) {
        if (['wday', 'date'].includes(payload.type)) {
          this.updateRule(payload)
        }else{
					// parse custom rules
					let repeating = payload.repeating[payload.repeating.type]

					if (repeating.length) {
						repeating.forEach(value => {
							if (payload.repeating.type == 'wdays') {
								this.updateRule({
									type: 'wday',
									wday: value,
									intervals: payload.intervals
								})
							} else if (payload.repeating.type == 'dates') {
								this.updateRule({
									type: 'date',
									date: value,
									intervals: payload.intervals
								})
							}
						})
					}
				}

				this.showModal = false
				return
			},

      scrollToFirstError() {
        const el = this.$el.querySelector('.form-error:first-of-type')

        if (! el) {
          return
        }

        el.parentElement.scrollIntoView()
      },

			saveAvailability() {
        this.$refs.availabilityForm.validate().then(success => {
          if (! success) {
            this.scrollToFirstError()
            return
          }

          this.loading = true

          this.$store.dispatch('guest/availability/update', {
            ...this.settings,
            rules: this.rules,
            guestId: this.guest.id,
          }).then(() => {
            this.$router.push({name: 'ThankYou'})
          }).catch(error => {
            this.$refs.availabilityForm.setErrors(error.response.data.errors)
          }).finally(() => this.loading = false)
        })
      },

      moment(date) {
        return moment(date)
      },

      deleteRule(callback, payload) {
        let index = this.rules.findIndex(rule => rule.type == 'date' && rule.date == payload.date)

        if (index === -1) {
          return
        }

        this.rules.splice(index, 1)

        callback.call()
      },

      freeSlot(checked, index, price) {
        this.$emit('input', checked)
        if (checked) {
          this.settings.interview_durations[index].price = 0;
        } else {
          this.settings.interview_durations[index].price = price == 0 ? 10 : price;
        }
      }
    },

    watch: {
      'settings.interview_durations': {
        handler: function (newVal) {
          for (let index = 0; index < newVal.length; index++) {
            if (newVal[index].price == '') {
              this.settings.interview_durations[index].price = "0";
            }
          }
        },
        deep: true
     },
    },


		created() {
      if (this.user.id && this.guest.id) {
        this.getAvailability()
      } else {
        this.$router.push({name: 'Home'})
        // this.$store.commit('guest/availability/setAvailability', {rules: []})
      }
      this.$store.commit('register/setCurrentStep', 5)
		},

		mounted() {
			window.Event.$on('saveAvailability', () => {
        this.saveAvailability()
      })
		},

    destroyed() {
      window.Event.$off('saveAvailability')
    }
	}
</script>

<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

</style>
