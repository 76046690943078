<template>
  <div>
    <div v-if="! guest.id" class="mt-12 max-w-md mx-auto flex justify-center space-y-4">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div class="mt-7 mb-2 max-w-3xl w-full">
      <div>
        <div class="md:py-6 last:border-b-0 border-gray-300 mb-5">
          <guest-cover-image/>
        </div>
        <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-5">
          <guest-profile-url/>
        </div>
        <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-5">
          <remove-branding :model="guest" entity="guest"/>
        </div>
        <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-5">
          <profile-color :model="guest" entity="guest"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import GuestCoverImage from '@/components/details/pro/GuestCoverImage.vue';
import GuestProfileUrl from '@/components/details/pro/GuestProfileUrl.vue';
import RemoveBranding from '@/components/details/pro/RemoveBranding.vue';
import ProfileColor from '@/components/details/pro/ProfileColor.vue';

  export default {
    components: {GuestCoverImage, GuestProfileUrl, RemoveBranding, ProfileColor},

    computed: {
      ...mapState({
        guest: state => state.guest.guest
      }),
    },
  }
</script>
