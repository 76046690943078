<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">I have experience in...</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <div>
          <ul class="list-disc flex flex-col space-y-3 pl-4" v-if="guest.experience.length">
            <li v-for="(experience, index) in guest.experience" :key="`experience-list-${index}`" class="text-blue-800 font-medium">
              {{ experience }}
            </li>
          </ul>
          <div v-else class="flex items-center text-sm text-gray-500">
            No experience added.
          </div>
        </div>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="experienceForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div v-if="! form.experience.length" class="h-12 text-sm text-gray-500">
              No experience added.
            </div>
            <div>
                <div class="flex mb-4" v-for="(item, index) in form.experience" :key="`list-item-${index}`">
                  <div class="w-full mr-3">
                    <ValidationProvider mode="eager" rules="required" :name="`Experience`" :vid="`experience.${index}`" v-slot="{ errors }">
                      <div>
                        <input type="text" class="guestio-form-input w-full" :class="{'error-class': errors.length}" placeholder="Type or paste experience" v-model="form.experience[index]">
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="w-1/12 my-auto">
                    <button class="rounded-full bg-gray-50 flex justify items-center p-2 hover:bg-softGray" @click.prevent="removeItem(index)">
                      <CrossIcon :classes="'w-6'"/>
                    </button>
                  </div>
                </div>
                <button type="button" class="text-pink-500  underline" @click.prevent="addItem">Add experience +</button>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Edit from "@/components/shared/Edit";
  import CrossIcon from "@/components/svgs/CrossIcon"
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {CrossIcon, SaveOrCancel, Edit},

    data() {
      return {
        working: false,
        edit: false,
        form: {
          experience: null,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.experience = this.guest.experience
      }
    },

    mounted() {
      this.form.experience = this.user.guest.experience
      this.form.id = this.user.guest.id
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch('guest/updateGuest', {
          ...this.guest,
          ...this.form,
        })
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.experienceForm.setErrors(error.response.data.errors)
            }
          })
      },

      addItem() {
        this.form.experience.push('')
      },

      removeItem(index) {
        this.form.experience.splice(index, 1)
      }
    },

    computed: {
      ...mapState({
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),
    },
  }
</script>

<style scoped>
  input.error-class {
    @apply border;
    border-color: #FF2F3B !important;
    background-color: #fff5f5 !important;
  }
</style>