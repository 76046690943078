<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Short description</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="text-blue-800 font-semibold">{{ guest.title }}</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="titleForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider rules="max:25" vid="title" name="Description" v-slot="{ errors }">
                <label for="short-desc" class="sr-only">Short description</label>
                <TextInput v-model="form.title" :errors="errors" placeholder="e.g. Nobel Prize winner"/>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Edit from "@/components/shared/Edit";
  import TextInput from "@/components/shared/TextInput";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, TextInput, SaveOrCancel},

    data() {
      return {
        working: false,
        edit: false,
        form: {
          title: null,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.title = this.guest.title
      }
    },

    mounted() {
      this.form.title = this.user.guest.title
      this.form.id = this.user.guest.id
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch('guest/updateGuest', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.titleForm.setErrors(error.response.data.errors)
            }
          })
      }
    },

    computed: {
      ...mapState({
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),
    },
  }
</script>