<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-gray-600">Pronunciation</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span v-if="guest.pronunciation" class="text-indigo-500 w-full">
          <AudioPreview :media-src="guest.pronunciation" />
        </span>
        <span v-else class="text-gray-500 opacity-50 text-sm">
          <em>(no pronunciation added)</em>
        </span>
        <Edit :title="guest.pronunciation ? 'Edit' : 'Add'" @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <div v-if="uploading" class="text-indigo-500 opacity-50 text-sm">
          <em>Uploading...</em>
        </div>
        <div v-if="! uploading">
          <div v-if="guest.pronunciation" class="mb-4 space-x-8 flex justify-between">
            <div class="w-full">
              <AudioPreview :media-src="guest.pronunciation"  />
            </div>

            <div>
              <ConfirmDialog
                @confirm="removePronunciation"
                confirm-action="Yes, Remove"
                dialog-title="Remove Pronunciation?"
                dialog-text="Are you sure you want to delete this pronunciation?"
                class="text-red-600 mt-1.5 flex items-center"
              >
                <TrashIcon class="h-5 w-5" />
                <span class="ml-1">Remove</span>
              </ConfirmDialog>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="w-full flex items-center space-x-2">
              <modal :with-close-button="false" class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
                <MicAltIcon class="text-pink-500 h-5" />
                <span class="ml-3">Record</span>

                <template #content="{ close }">
                  <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                    <RecordAudioPronunciation @close="close" @update-pronunciation="savePronunciation" />
                  </div>
                </template>
              </modal>

              <span class="text-gray-500">- or -</span>

              <label class="cursor-pointer bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
                <UploadIcon class="text-pink-500 h-5" />
                <span class="ml-3">Upload</span>
                <input type="file" class="hidden" accept="audio/*" @change="uploadPronunciation($event)">
              </label>
            </div>

            <div>
              <button type="button" class="detail-cancel-button" @click.prevent="edit = false">
                Close
              </button>
            </div>
          </div>

          <div v-if="uploadingError" class="mt-2">
            <span class="text-sm text-red-600">
              {{ uploadingError }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import { mapState, mapMutations } from "vuex";
  import Edit from "@/components/shared/Edit";
  import Modal from '@/components/shared/Modal'
  import AudioPreview from '@/components/shared/AudioPreview'
  import MicAltIcon from '@/components/svgs/MicAltIcon'
  import UploadIcon from '@/components/svgs/UploadIcon'
  import RecordAudioPronunciation from './RecordAudioPronunciation'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'
  import TrashIcon from '@/components/svgs/TrashIcon'

  export default {
    components: {
      Edit,
      Modal,
      MicAltIcon,
      UploadIcon,
      RecordAudioPronunciation,
      AudioPreview,
      ConfirmDialog,
      TrashIcon,
    },

    data() {
      return {
        edit: false,
        uploading: false,
        uploadingError: null,
      }
    },

    computed: {
      ...mapState({
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),
    },

    watch: {
      edit(edit) {
        if (edit) return
      }
    },

    methods: {
      ...mapMutations({
        setGuestPronunciation: 'guest/setPronunciation',
      }),
      savePronunciation(media) {
        this.uploading = true
        this.uploadingError = null

        api
          .post(`/guests/${this.guest.id}/pronunciation`, {
            media_id: media.id,
          })
          .then(({ data }) => {
            this.setGuestPronunciation(data.data.url)
            console.log(data)
          })
          .catch(error => {
            if (error.response) {
              this.uploadingError = error.response.data.message
              return
            }

            this.uploadingError = 'Error! The media file was not saved.'
          })
          .finally(() => {
            this.uploading = false
          })
      },

      uploadPronunciation(e) {
        if (e.target.files.length === 0 ) {
          return
        }

        this.uploading = true
        this.uploadingError = null

        let uploadForm = new FormData()

        uploadForm.append('media', e.target.files[0])

        api.post(`/guests/${this.guest.id}/pronunciation`, uploadForm)
          .then(({ data }) => {
            this.setGuestPronunciation(data.data.url)
            console.log(data)
          })
          .catch(error => {
            if (error.response) {
              this.uploadingError = error.response.data.message
              return
            }

            this.uploadingError = 'Error! The media file was not saved.'
          })
          .finally(() => {
            this.uploading = false
            e.target.value = null
          })
      },

      removePronunciation(callback) {
        api
          .delete(`/guests/${this.guest.id}/pronunciation`)
          .then(() => {
            this.setGuestPronunciation(null)
            this.$toast.success('Pronunciation removed!')
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
          })
          .finally(() => callback())
      }
    },

    mounted() {
      //
    },
  }
</script>
