<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Your Image</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <MediaUploadSingle
        entity="guest"
        :working="working"
        :modelId="guest.id"
        :imageUrl="form.imageUrl"
        :imageUuid="guest.imageUuid"
        :error="uploadingError"
        @uploaded="uploaded"
        @deleted="deleted"
      />
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import MediaUploadSingle from '@/components/shared/MediaUploadSingle';

  export default {
    components: { MediaUploadSingle },

    data() {
      return {
        working: false,
        uploadingError: false,
        form: {
          imageUrl: null,
        }
      }
    },

    mounted() {
      this.form.id = this.user.guest.id
      this.form.imageUrl = this.user.guest.imageUrl
    },

    methods: {
      update() {
        this.working = true
        this.uploadingError = false

        this.$store.dispatch('guest/updateGuest', this.form)
          .then(() => {
            this.working = false
          })
          .catch(() => {
            this.working = false
            this.uploadingError = true
          })
      },

      uploaded(url) {
        this.form.imageUrl = url
        this.update()
      },

      deleted() {
        this.form.imageUrl = null
      },
    },

    computed: {
      ...mapState({
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),
    },
  }
</script>