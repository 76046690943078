<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Your Name</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="text-blue-800 font-semibold">{{ guest.name }}</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="nameForm" v-slot="{ handleSubmit }">
          <form ref="form" @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider mode="lazy" rules="required|max:255" vid="name" name="Your name" v-slot="{ errors }">
                <label for="name" class="sr-only">Your Name</label>
                <TextInput v-model="form.name" :errors="errors" placeholder="Your Name"/>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Edit from "@/components/shared/Edit";
  import TextInput from "@/components/shared/TextInput";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, TextInput, SaveOrCancel},

    data() {
      return {
        working: false,
        edit: false,
        form: {
          name: null,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.name = this.guest.name
      }
    },

    mounted() {
      this.form.name = this.user.guest.name
      this.form.id = this.user.guest.id
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch('guest/updateGuest', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.nameForm.setErrors(error.response.data.errors)
            }
          })
      }
    },

    computed: {
      ...mapState({
        guest: state => state.guest.guest,
        user: state => state.auth.user
      }),
    },
  }
</script>